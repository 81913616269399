/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");

import Vue from 'vue';
import 'livewire-vue'
window.Vue = Vue 

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

Vue.component(
    "example-component",
    require("./components/ExampleComponent.vue")
);
// Vue.component('banquet-cost-form', require('./components/BanquetCostForm.vue'));
// Vue.component('lead-contact', require('./components/LeadContact.vue'));
// Vue.component('event-details', require('./components/EventDetails.vue'));
// Vue.component('edit-event-details', require('./components/EditEventDetails.vue'));
// Vue.component('catering-calc', require('./components/CateringCalc.vue'));

Vue.component("banquet-cost-form", () =>
    import(
        /* webpackChunkName: "lead-contact" */ "./components/BanquetCostForm.vue"
    )
);
Vue.component("lead-contact", () =>
    import(
        /* webpackChunkName: "lead-contact" */ "./components/LeadContact.vue"
    )
);
Vue.component("apply", () =>
    import(
        /* webpackChunkName: "lead-contact" */ "./components/Apply.vue"
    )
);
Vue.component("photography-questionnaire", () =>
    import(
        /* webpackChunkName: "photography-questionnaire" */ "./components/PhotographyQuestionnaire.vue"
    )
);

Vue.component("event-details", () =>
    import(
        /* webpackChunkName: "event-details" */ "./components/EventDetails.vue"
    )
);

Vue.component("catering-calc", () =>
    import(/* webpackChunkName: "catering-calc" */ "./components/CateringCalc")
);

Vue.component("dj-submittal", () =>
    import(
        /* webpackChunkName: "dj-submittal" */ "./components/DJSubmittal.vue"
    )
);

Vue.component("pastry-submittal", () =>
    import(
        /* webpackChunkName: "pastry-submittal" */ "./components/PastrySubmittal.vue"
    )
);

Vue.component("additional-services", () =>
    import(
        /* webpackChunkName: "additional-services" */ "./components/AdditionalServicesForm.vue"
    )
);

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key)))

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: "#app"
});
